.mapContainer {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  width: 350px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
}

.sidebar.open {
  transform: translateX(0);
}

.sidebarToggle {
  position: absolute;
  top: 50%;
  right: -40px;
  width: 40px;
  height: 60px;
  background-color: #68a834;
  border: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
}

.sidebarToggle svg {
  width: 24px;
  height: 24px;
  color: white;
  transition: transform 0.3s ease;
}

.sidebar.open .sidebarToggle svg {
  transform: rotate(180deg);
}

.sidebarContent {
  padding: 20px;
  height: 100%;
  overflow-y: auto;
}

.categoryButtons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.categoryButton {
  padding: 8px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  background-color: white;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: all 0.2s ease;
}

.categoryButton:hover {
  background-color: #f5f5f5;
}

.categoryButton.active {
  background-color: #68a834;
  color: white;
  border-color: #68a834;
}

.categoryButton.active svg {
  color: white;
}

.searchContainer {
  position: relative;
  margin-bottom: 20px;
}

.searchIcon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
}

.searchInput {
  width: 100%;
  padding: 10px 10px 10px 40px;
  border: none;
  border-radius: 25px;
  background-color: #f0f0f0;
  font-size: 16px;
}

.locationList {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.locationItem {
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: all 0.3s ease;
}

.locationItem:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.locationItem.active {
  border-left: 4px solid #68a834;
  background-color: #f0f9ff;
}

.locationItem h3 {
  font-size: 18px;
  margin-bottom: 5px;
  color: #333;
}

.locationItem p {
  font-size: 14px;
  color: #666;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.modalContent {
  background-color: white;
  padding: 30px;
  border-radius: 15px;
  width: 90%;
  max-width: 500px;
  position: relative;
}
.modalContent h2{
  line-height: 120%;
}

.closeModal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
}

.locationImage {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 20px;
}

.locationDetails {
  margin-bottom: 20px;
}

.detailItem {
  display: flex;
  margin-top: 10px;
  align-items: center;
  margin-bottom: 10px;
}

.detailItem svg {
  margin-right: 10px;
  color: #68a834;
}

.actionButtons {
  display: flex;
  gap: 10px;
}

.directionButton,
.callButton {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.directionButton {
  background-color: #33ccff;
  color: white;
}

.callButton {
  background-color: #68a834;
  color: white;
}

.loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3000;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #7fdbff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mobileMenuButton {
  display: none;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1001;
  background-color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    transform: translateX(-100%);
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .sidebarToggle {
    display: none;
  }

  .mobileMenuButton {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modalContent {
    width: 95%;
    padding: 20px;
  }

  .categoryButtons {
    flex-wrap: wrap;
    justify-content: center;
  }

  .categoryButton {
    font-size: 12px;
    padding: 6px 10px;
  }
}

@media (max-width: 480px) {
  .actionButtons {
    flex-direction: column;
  }

  .locationItem h3 {
    font-size: 16px;
  }

  .locationItem p {
    font-size: 12px;
  }

  .modalContent {
    padding: 15px;
  }

  .locationImage {
    height: 150px;
  }
}

